/**
 * 自动生成的代码，请勿修改
 */
import { doRequest } from './utils';
import type { ResponseType } from './utils';
import type {
  RegionSearchRequest,
  ResponseListRegionSearchResponse,
  RegionRecommendRequest,
  ResponseRegionRecommendResponse,
  RegionPopularRequest,
  ResponseListRegionPopularResponse,
  ResponseString,
  RegionCurrentCityRequest,
  ResponseRegionAzgoDTO,
  BaseRequestDTO,
  PageHotelRequest,
  ResponsePageablePageHotelResponse,
  H5QueryHotelDetailRequest,
  ResponseH5QueryHotelDetailResponse,
  H5ListHotelRoomTypeRequest,
  ResponseH5ListHotelRoomTypeResponse,
  H5QueryRoomTypeDetailRequest,
  ResponseH5QueryRoomTypeDetailResponse,
} from '../types';

const baseUrl = '';

class ApiService {
  /**
   * @summary 健康检查
   * @fullUrl /healthCheck
   */
  public healthCheckGet(
  ): ResponseType<string> {
    const requestUrl = baseUrl + '/healthCheck';
    const res = doRequest<string>({
      method: 'get',
      url: requestUrl,
    });
    return res;
  }
  /**
   * @summary 搜索地区
   * @fullUrl /region/search
   */
  public regionSearchPost(
    body: RegionSearchRequest,
  ): ResponseType<ResponseListRegionSearchResponse> {
    const requestUrl = baseUrl + '/region/search';
    const res = doRequest<ResponseListRegionSearchResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 首页推荐地区
   * @fullUrl /region/recommend
   */
  public regionRecommendPost(
    body: RegionRecommendRequest,
  ): ResponseType<ResponseRegionRecommendResponse> {
    const requestUrl = baseUrl + '/region/recommend';
    const res = doRequest<ResponseRegionRecommendResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 热门城市
   * @fullUrl /region/popular
   */
  public regionPopularPost(
    body: RegionPopularRequest,
  ): ResponseType<ResponseListRegionPopularResponse> {
    const requestUrl = baseUrl + '/region/popular';
    const res = doRequest<ResponseListRegionPopularResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 手动清除redis缓存
   * @fullUrl /region/clearCache
   */
  public regionClearCachePost(
  ): ResponseType<ResponseString> {
    const requestUrl = baseUrl + '/region/clearCache';
    const res = doRequest<ResponseString>({
      method: 'post',
      url: requestUrl,
    });
    return res;
  }
  /**
   * @summary region current city
   * @fullUrl /region/regionCurrentCity
   */
  public regionRegionCurrentCityPost(
    body: RegionCurrentCityRequest,
  ): ResponseType<ResponseRegionAzgoDTO> {
    const requestUrl = baseUrl + '/region/regionCurrentCity';
    const res = doRequest<ResponseRegionAzgoDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 内链城市
   * @fullUrl /region/internalLinkCity
   */
  public regionInternalLinkCityPost(
    body: BaseRequestDTO,
  ): ResponseType<ResponseListRegionPopularResponse> {
    const requestUrl = baseUrl + '/region/internalLinkCity';
    const res = doRequest<ResponseListRegionPopularResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询酒店列表
   * @fullUrl /hotel/list
   */
  public hotelListPost(
    body: PageHotelRequest,
  ): ResponseType<ResponsePageablePageHotelResponse> {
    const requestUrl = baseUrl + '/hotel/list';
    const res = doRequest<ResponsePageablePageHotelResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询酒店详情
   * @fullUrl /hotel/detail
   */
  public hotelDetailPost(
    body: H5QueryHotelDetailRequest,
  ): ResponseType<ResponseH5QueryHotelDetailResponse> {
    const requestUrl = baseUrl + '/hotel/detail';
    const res = doRequest<ResponseH5QueryHotelDetailResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询酒店/房型列表
   * @fullUrl /hotel/roomType/list
   */
  public hotelRoomTypeListPost(
    body: H5ListHotelRoomTypeRequest,
  ): ResponseType<ResponseH5ListHotelRoomTypeResponse> {
    const requestUrl = baseUrl + '/hotel/roomType/list';
    const res = doRequest<ResponseH5ListHotelRoomTypeResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 房型详情
   * @fullUrl /hotel/roomType/detail
   */
  public hotelRoomTypeDetailPost(
    body: H5QueryRoomTypeDetailRequest,
  ): ResponseType<ResponseH5QueryRoomTypeDetailResponse> {
    const requestUrl = baseUrl + '/hotel/roomType/detail';
    const res = doRequest<ResponseH5QueryRoomTypeDetailResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
}

export const api = new ApiService();
